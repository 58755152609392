import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSlider from "../../hooks/useSlider";
import Pagination from "../../utils/Pagination";
import Sidebar from "../sidebar/Sidebar";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import useDetails from "../../hooks/useDetails";
import { constant } from "../../utils/constants";
import DeleteModal from "../../components/DeleteModal";
import { deleteDataList, getDataList ,exportDataListExcel,downloadDataListExcel, handleImportDataList} from "../../services/services";
import moment from "moment";
import { toastAlert } from "../../utils/SweetAlert";
import i18next from "i18next";
import CheckPermission from "../../components/CheckPermission";
import FileModal from "../../components/FileModal";
import { downloadFile } from "../../helper/helper";
import { FaDownload, FaFileExport, FaFileImport } from "react-icons/fa";

const ReceivingList = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const details = useDetails();
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [company, setCompany] = useState( "");

  const { data, refetch } = useQuery({
    queryKey: ["data-list", page, limit],
    queryFn: async () => {
      const resp = await getDataList(
        page,
        limit,
        search,
        constant.DATA_LIST.RECEIVING_LIST,
        details?.companyUID,
        true,
        true
      );
      return resp?.data?.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteDataList(id, details?.companyUID),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });
  const fileUploadMutation = useMutation({
    mutationFn: ({ formData, domain }) =>
      handleImportDataList(formData, domain, constant.DATA_LIST.RECEIVING_LIST),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      refetch();
    },
  });


  const handleAction = async (data) => {
    try {
      if (data?.type === constant.FILE_ACTION.IMPORT) {
        const formData = new FormData();
        formData.append("file", data?.file);
        fileUploadMutation.mutate({ formData, domain:details?.companyUID});
      } else {
        const domain = details?.companyUID;
        const response =
          data?.type === constant.FILE_ACTION.EXPORT
            ? await exportDataListExcel(
                domain,
                constant.DATA_LIST.RECEIVING_LIST
              )
            : await downloadDataListExcel(
                domain,
                constant.DATA_LIST.RECEIVING_LIST
              );

        downloadFile(response?.data, `${domain}_recevingList.xlsx`);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setShow(false);
    }
  };


  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        {/* <Row>
          <Col xl={6}>
            <h2 className="mainhead font-24">{t("receiving.receivingList")}</h2>
          </Col>
          <Col className="text-end" xl={6}>
            <button className="userBtn btn-theme me-2"
            onClick={() => {
              if (!data?.datalists?.length) {
                toastAlert("error", "Category not found to export");
                return;
              }
               setShow({ company: details?.companyUID, type: constant.FILE_ACTION.EXPORT,  });
            }}>
              {t("receiving.export")}
              
            </button>
            <CheckPermission
              permission={constant.PERMISSIONS.CAN_CREATE_RECEIVING_LISTS}
            >
              <Link className="userBtn btn-theme" to="../addreceivinglist">
                {t("receiving.addNew")}
              </Link>
            </CheckPermission>
          </Col>
        </Row> */}

          <Row>
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="mainhead font-24">{t("receiving.receivingList")}</h2>
        
                    <div className="d-flex align-items-center gap-2">
                      <button
                        className="userBtn btn-theme me-2 p-3"
                        onClick={() => {
                          setShow({
                            company: details?.companyUID,
                            type: constant.FILE_ACTION.DOWNLOAD,
                          });
                        }}
                        title="Download Sample"
                      >
                        <FaDownload />
                      </button>
        
                      <label
                        className="userBtn btn-theme me-2 p-3"
                        role="button"
                        htmlFor="import-catergory"
                        title="Import"
                      >
                        <FaFileImport />
                      </label>
                      <button
                        className="userBtn btn-theme me-2 p-3"
                        title="Export"
                        onClick={() => {
                          if (!data?.datalists?.length) {
                            toastAlert("error", "Recieving list not found to export");
                            return;
                          }
                          setShow({
                            company: details?.companyUID,
                            type: constant.FILE_ACTION.EXPORT,
                          });
                        }}
                      >
                        <FaFileExport />
                      </button>
                      <input
                        type="file"
                        className="d-none"
                        id="import-catergory"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={(e) => {
                          if (
                            e.target.files[0]?.type !==
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          ) {
                            toastAlert("error", t("errorMsg.xlsxFormat"));
                            return;
                          }
                          setShow({
                            company: details?.companyUID,
                            type: constant.FILE_ACTION.IMPORT,
                            file: e.target.files[0],
                          });
                        }}
                        onClick={(e) => {
                          e.target.value = null;
                        }}
                      />
                     <CheckPermission
              permission={constant.PERMISSIONS.CAN_CREATE_RECEIVING_LISTS}
            >
              <Link className="userBtn btn-theme" to="../addreceivinglist">
                {t("receiving.addNew")}
              </Link>
            </CheckPermission>
                    </div>
                  </div>
                </Row>
        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th> {t("receiving.receivingListName")}</th>
                <th> {t("receiving.deliveryReceivingDate")}</th>
                <th> {t("receiving.vendorDistributor")}</th>
                <th> {t("receiving.item")}</th>
                <th> {t("receiving.unitMeasure")}</th>
                <th> {t("receiving.packCount")}</th>
                <th> {t("receiving.qtyToReceive")}</th>
                <th> {t("receiving.receivedLocaiton")}</th>
                <CheckPermission
                  permission={constant.PERMISSIONS.CAN_CREATE_RECEIVING_LISTS}
                >
                  {" "}
                  <th> {t("globals.action")}</th>
                </CheckPermission>
              </tr>
            </thead>
            <tbody>
              {data?.datalists && data?.datalists?.length > 0 ? (
                data?.datalists?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.name}</td>
                      <td>
                        {moment(item?.details?.deliveryDate).format("ll")}
                      </td>
                      <td>{item?.details?.vendorName}</td>
                      <td>
                        {i18next?.language == "es"
                          ? item?.itemDetails?.es_name
                          : item?.itemDetails?.en_name}
                      </td>
                      <td>{item?.details?.measureUnit}</td>
                      <td>{item?.details?.packCount}</td>
                      <td>{item?.details?.receivedQuantity}</td>
                      <td>
                        {item?.assignAll
                          ? t("globals.allLocations")
                          : item?.locations?.map((i) => i?.name)?.join(", ")}
                      </td>
                      <CheckPermission
                        permission={
                          constant.PERMISSIONS.CAN_CREATE_RECEIVING_LISTS
                        }
                      >
                        <td>
                          <Link
                            to={`../addreceivinglist?id=${item?._id}`}
                            role="button"
                          >
                            <img src="./images/Edit.svg" alt="" />
                          </Link>

                          <img
                            src="./../images/Delete.svg"
                            alt="delete"
                            onClick={() => setShowDelete(item?._id)}
                            role="button"
                          />
                        </td>
                      </CheckPermission>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("receiving.noReceivingList")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.datalists?.length}
        />
      </div>
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
      <FileModal
        show={show}
        handleClose={() => setShow(false)}
        handleAction={handleAction}
      />
    </div>
  );
};

export default ReceivingList;
