import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useDetails from "../../hooks/useDetails";
import useSlider from "../../hooks/useSlider";
import { deleteDataList, downloadDataListExcel, exportDataListExcel, getDataList, handleImportDataList } from "../../services/services";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import Sidebar from "../sidebar/Sidebar";
import CheckPermission from "../../components/CheckPermission";
import { FaDownload, FaFileExport, FaFileImport } from "react-icons/fa";
import DeleteModal from "../../components/DeleteModal";
import FileModal from "../../components/FileModal";
import { toastAlert } from "../../utils/SweetAlert";
import { downloadFile } from "../../helper/helper";

const TaskList = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const details = useDetails();
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  

  const { data, refetch } = useQuery({
    queryKey: ["data-list", page, limit],
    queryFn: async () => {
      const resp = await getDataList(
        page,
        limit,
        search,
        constant.DATA_LIST.TASK_LIST,
        details?.companyUID,
        true,
        true
      );
      return resp?.data?.data;
    },
  });
   const deleteMutation = useMutation({
      mutationFn: (id) => deleteDataList(id, details?.companyUID),
      onSuccess: (resp) => {
        refetch();
        setShowDelete(false);
        toastAlert("success", resp?.data?.message);
      },
    });
  
      const fileUploadMutation = useMutation({
        mutationFn: ({ formData, domain }) =>
          handleImportDataList(formData, domain, constant.DATA_LIST.TASK_LIST),
        onSuccess: (resp) => {
          toastAlert("success", resp?.data?.message);
          refetch();
        },
      });
  
    
      const handleAction = async (data) => {
        try {
          if (data?.type === constant.FILE_ACTION.IMPORT) {
            const formData = new FormData();
            formData.append("file", data?.file);
            fileUploadMutation.mutate({ formData, domain:details?.companyUID });
          } else {
            const domain = details?.companyUID;
            const response =
              data?.type === constant.FILE_ACTION.EXPORT
                ? await exportDataListExcel(domain, constant.DATA_LIST.TASK_LIST)
                : await downloadDataListExcel(domain, constant.DATA_LIST.TASK_LIST);
    
            downloadFile(response?.data, `${domain}_taskList.xlsx`);
          }
        } catch (err) {
          console.error("Error:", err);
        } finally {
          setShow(false);
        }
      };
  

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
         <div className="d-flex align-items-center justify-content-between">
         {/* <Col xl={6} className=""> */}
            <h2 className="mainhead font-24">{t("task.taskList")}</h2>
          {/* </Col> */}
                  <div className="d-flex align-items-center gap-2">
                    <button
                      className="userBtn btn-theme me-2"
                      onClick={() => {
                        setShow({company: details?.companyUID, type: constant.FILE_ACTION.DOWNLOAD });
                      }}
                      title="Download Sample"
                    >
                      <FaDownload />
                    </button>
        
                    <label
                      className="userBtn btn-theme me-2"
                      role="button"
                      htmlFor="import-catergory"
                      title="Import"
                    >
                      <FaFileImport />
                    </label>
                    <button
                      className="userBtn btn-theme me-2"
                      title="Export"
                      onClick={() => {
                        if (!data?.datalists?.length) {
                          toastAlert("error", "Category not found to export");
                          return;
                        }
                        setShow({ company: details?.companyUID, type: constant.FILE_ACTION.EXPORT });
                      }}
                    >
                      <FaFileExport />
                    </button>
                    <input
                      type="file"
                      className="d-none"
                      id="import-catergory"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      onChange={(e) => {
                        if (
                          e.target.files[0]?.type !==
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ) {
                          toastAlert("error", t("errorMsg.xlsxFormat"));
                          return;
                        }
                        setShow({
                          company: details?.companyUID,
                          type: constant.FILE_ACTION.IMPORT,
                          file: e.target.files[0],
                        });
                      }}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                    />
                 <CheckPermission
              permission={constant.PERMISSIONS.CAN_CREATE_TASK_LISTS}
            >
              <Link className="userBtn btn-theme" to="../addnewtask">
                {t("globals.addNew")}
              </Link>
            </CheckPermission>
                  </div>
                </div>
        {/* <Row>
          <Col xl={6} className="">
            <h2 className="mainhead font-24">{t("task.taskList")}</h2>
          </Col>
          <Col className="text-end" xl={6}>
            <CheckPermission
              permission={constant.PERMISSIONS.CAN_CREATE_TASK_LISTS}
            >
              <Link className="userBtn btn-theme" to="../addnewtask">
                {t("globals.addNew")}
              </Link>
            </CheckPermission>
          </Col>
        </Row> */}
        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>{t("task.taskListName")}</th>
                <th>{t("task.taskName")}</th>
                <th>{t("task.employeeName")}</th>
                <th>{t("task.createdOn")}</th>
                <th>{t("task.taskDate")}</th>
                <th>{t("task.startTime")}</th>
                <th>{t("task.endTime")}</th>
                <th>{t("task.taskLocation")}</th>
                {/* <th>{t("task.comment")}</th> */}
                <th>{t("globals.action")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.datalists && data?.datalists?.length > 0 ? (
                data?.datalists?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.name}</td>
                      <td>{item?.details?.taskName}</td>
                      <td>
                        {item?.customerDetails?.userInfo?.name
                          ? item?.customerDetails?.userInfo?.name
                          : "N/A"}
                      </td>
                      <td>{moment(item?.createdAt).format("lll")}</td>
                      <td>
                        {item?.details?.dailyTask
                          ? t("task.dailyRecuuringTask")
                          : moment(item?.details?.taskDate).format("ll")}
                      </td>
                      <td>
                        {item?.details?.startTime
                          ? item?.details?.startTime
                          : "N/A"}
                      </td>
                      <td>
                        {item?.details?.endTime
                          ? item?.details?.endTime
                          : "N/A"}
                      </td>
                      <td>
                        {item?.assignAll
                          ? t("globals.allLocations")
                          : item?.locations?.map((i) => i?.name)?.join(", ")}
                      </td>
                      <td>
                        <Link to={`../task-details/${item?._id}`} role="button">
                          <img
                            src="/images/eye.svg"
                            className="img-fluid actionIcon"
                          />
                        </Link>
                        <CheckPermission
                          permission={
                            constant.PERMISSIONS.CAN_CREATE_TASK_LISTS
                          }
                        >
                          <Link
                            to={`../addnewtask?id=${item?._id}`}
                            role="button"
                          >
                            <img src="/images/Edit.svg" alt="" />
                          </Link>
                        </CheckPermission>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("task.noTaskListFound")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.datalists?.length}
        />
         <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
       <FileModal
        show={show}
        handleClose={() => setShow(false)}
        handleAction={handleAction}
      />
      </div>
    </div>
  );
};

export default TaskList;
