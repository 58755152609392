import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsThreeDotsVertical } from "react-icons/bs";
import useSlider from "../../hooks/useSlider";
import Sidebar from "../sidebar/Sidebar";
import "./notification.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import { constant } from "../../utils/constants";
import {
  deleteNotification,
  getNotifications,
  markAllRead,
  markAsReadNotification,
} from "../../services/services";
import Pagination from "../../utils/Pagination";
import moment from "moment";
import DeleteModal from "../../components/DeleteModal";
import { toastAlert } from "../../utils/SweetAlert";
import useDetails from "../../hooks/useDetails";
import { Link } from "react-router-dom";

const Notification = () => {
  const isSlider = useSlider();
  const details = useDetails();
  const { t } = useTranslation();
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const [showDelete, setShowDelete] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["notifications", page, limit],
    queryFn: async () => {
      const resp = await getNotifications({ page, limit });
      return resp?.data?.data ?? [];
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteNotification(id),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  const markRead = useMutation({
    mutationFn: (id) => markAsReadNotification(id),
    onSuccess: () => {
      refetch();
    },
  });

  const markAll = useMutation({
    mutationFn: () => markAllRead(),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      refetch();
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <section
        className={isSlider ? "body-content close" : "body-content open"}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2 className="main-heading fontSize24">
              {t("notification.notificationNews")}
            </h2>
            <div className="d-flex justify-content-between align-items-center">
              {data?.notifications?.some((item) =>
                item?.companies?.[0]?.participants?.some(
                  (participant) =>
                    participant.userId === details?.id && !participant.isRead
                )
              ) && (
                <button
                  className="userBtn btn-theme me-3"
                  type="button"
                  onClick={() => markAll.mutate()}
                >
                  Mark All Read
                </button>
              )}

              <Link className="userBtn btn-theme" to="../new-notification">
                New
              </Link>
            </div>
          </div>
          <div className="notification">
            <div className="row">
              <div className="col-md-12 mb-3">
                <ul>
                  {data?.notifications && data?.notifications?.length > 0 ? (
                    data?.notifications?.map((item, index) => {
                      let isRead = item?.companies?.[0]?.participants?.find(
                        (i) => i.userId == details?.id
                      )?.isRead;

                      return (
                        <li className={isRead ? "" : "active"} key={index}>
                          <div className="listLeft">
                            {/* <div className="userProfile">
                              <img
                                src="/images/profile.jpg"
                                alt="img"
                                className="userImg"
                              />
                            </div> */}
                            <div className="notification-content ms-3">
                              <p className="notificationHeading">
                                {item?.subject}
                              </p>
                              <p>{item?.description}</p>
                              <p className=" notificationHeading ">
                                {moment(item?.createdAt).fromNow()}
                              </p>
                            </div>
                          </div>
                          <div className="rightList">
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                              >
                                <BsThreeDotsVertical />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => markRead.mutate(item?._id)}
                                >
                                  Mark As Read
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => setShowDelete(item?._id)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <li className="d-flex justify-content-center my-4">
                      <h4>No Notification Found</h4>
                    </li>
                  )}
                </ul>
                <Pagination
                  page={page}
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                  totalPages={data?.total_pages}
                  total={data?.total}
                  length={data?.notifications?.length}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
    </div>
  );
};

export default Notification;
