import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal";
import useSlider from "../../hooks/useSlider";
import { deleteUser, downloadUserExcel, exportUserExcel, getUser, handleUserImport } from "../../services/services";
import { Search } from "../../SvgIcons/allIcons";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";
import useDetails from "../../hooks/useDetails";
import CheckPermission from "../../components/CheckPermission";
import { downloadFile, noSpecialChars } from "../../helper/helper";
import { FaDownload, FaFileExport, FaFileImport } from "react-icons/fa";
import FileModal from "../../components/FileModal";

const Usermanagement = () => {
  const { t } = useTranslation();
  const [showDelete, setShowDelete] = useState(false);
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const [role, setRole] = useState("all");
  const isSlider = useSlider();
  const details = useDetails();
  const [show, setShow] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["user-list", page, limit, role, details?.userCompany?._id],
    queryFn: async () => {
      const resp = await getUser(
        page,
        limit,
        search,
        role,
        details?.userCompany?._id
      );
      return resp?.data?.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteUser(id),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  const fileUploadMutation = useMutation({
    mutationFn: ({ formData, domain }) =>
      handleUserImport(formData, domain),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      refetch();
    },
  });

  const handleAction = async (data) => {
    try {
      if (data?.type === constant.FILE_ACTION.IMPORT) {
        const formData = new FormData();
        formData.append("file", data?.file);
        fileUploadMutation.mutate({ formData, domain: details?.userCompany?._id });
      } else {
        const domain = details?.userCompany?._id;
        console.log(details?.userCompany?._id,role,"datasss");
        let param={
          companyId:details?.userCompany?._id,
          role:role,
          // brandId:brand
        }
        const response =
          data?.type === constant.FILE_ACTION.EXPORT
            ? await exportUserExcel({domain:domain,params:param})
            : await downloadUserExcel(domain);

        downloadFile(response?.data, `${domain}_users.xlsx`);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setShow(false);
    }
  };

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        {/* <Row>
          <Col xl={6}>
            <h2 className="mainhead font-24">{t("user.userManagement")}</h2>
          </Col>
          <Col className="text-end" xl={6}>
            <CheckPermission
              permission={constant.PERMISSIONS.CAN_ADD_NEW_USERS}
            >
              <Link className="userBtn btn-theme" to="../addNewUser">
                {t("user.addNewUser")}
              </Link>
            </CheckPermission>
          </Col>
        </Row> */}
       <Row>
                <div className="d-flex align-items-center justify-content-between">
                <h2 className="mainhead font-24">{t("user.userManagement")}</h2>
      
                  <div className="d-flex align-items-center gap-2">
                    <button
                      className="userBtn btn-theme me-2 p-3"
                      onClick={() => {
                        setShow({
                          company: details?.userCompany?._id,
                          type: constant.FILE_ACTION.DOWNLOAD,
                        });
                      }}
                      title="Download Sample"
                    >
                      <FaDownload />
                    </button>
      
                    <label
                      className="userBtn btn-theme me-2 p-3"
                      role="button"
                      htmlFor="import-catergory"
                      title="Import"
                    >
                      <FaFileImport />
                    </label>
                    <button
                      className="userBtn btn-theme me-2 p-3"
                      title="Export"
                      onClick={() => {
                        if (!data?.users?.length) {
                          toastAlert("error", "User not found to export");
                          return;
                        }
                        setShow({
                          company: details?.userCompany?._id,
                          type: constant.FILE_ACTION.EXPORT,
                        });
                      }}
                    >
                      <FaFileExport />
                    </button>
                    <input
                      type="file"
                      className="d-none"
                      id="import-catergory"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      onChange={(e) => {
                        if (
                          e.target.files[0]?.type !==
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ) {
                          toastAlert("error", t("errorMsg.xlsxFormat"));
                          return;
                        }
                        setShow({
                          company: details?.userCompany?._id,
                          type: constant.FILE_ACTION.IMPORT,
                          file: e.target.files[0],
                        });
                      }}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                    />
                       <Col className="text-end" xl={6}>
            <CheckPermission
              permission={constant.PERMISSIONS.CAN_ADD_NEW_USERS}
            >
              <Link className="userBtn btn-theme" to="../addNewUser">
                {t("user.addNewUser")}
              </Link>
            </CheckPermission>
          </Col>
                  </div>
                </div>
              </Row>
        <Row className="align-items-center">
          <Col md={8}>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center me-3">
                <label className="labelText">{t("user.role")}:</label>
                <select
                  className="form-select selectfeild"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="all">{t("user.all")}</option>
                  <option value="admin">{t("user.admin")}</option>
                  <option value="manager">{t("user.manager")}</option>
                  <option value="user">{t("user.user")}</option>
                </select>
              </div>
            </div>
          </Col>
          <Col md={4} className="py-4">
            <div className="sidebarBox">
              <div className="sidebar-box search-form-wrap mb-0">
                <div className="search-form">
                  <div className="form-group">
                    <span className="iconsearch">
                      <Search />
                    </span>
                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyDown={(e) => {
                        noSpecialChars(e);
                        if (e.key == "Enter" && search.trim() !== "") refetch();
                      }}
                      onKeyUp={(e) =>
                        e.target.value == "" &&
                        e.key == "Backspace" &&
                        refetch()
                      }
                      type="text"
                      className="form-control"
                      placeholder={t("globals.search")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div className="table-responsive tableOut">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>#</th>
                <th>{t("user.custId")}</th>
                <th>{t("user.name")}</th>
                <th>{t("user.emailAddress")} </th>
                <th>{t("user.role")}</th>
                <th>{t("user.added")} </th>
                <th>{t("globals.action")} </th>
              </tr>
            </thead>
            <tbody>
              {data?.users && data?.users?.length > 0 ? (
                data?.users?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{(page - 1) * limit + (index + 1)}</td>
                      <td>{item?.userId}</td>
                      <td className="text-capitalize">
                        {item?.userInfo?.name}
                      </td>
                      <td>{item?.email}</td>
                      <td className="text-capitalize">{item?.role}</td>
                      <td>{moment(item?.createdAt).format("lll")}</td>
                      <td>
                        <Link to={`../userdetail/${item?._id}`}>
                          <img
                            src="/images/eye.svg"
                            className="img-fluid actionIcon"
                          />
                        </Link>
                        <CheckPermission
                          permission={constant.PERMISSIONS.CAN_ADD_NEW_USERS}
                        >
                          <Link to={`../addNewUser?id=${item?._id}`}>
                            <img
                              src="/images/Edit.svg"
                              className="img-fluid actionIcon"
                            />
                          </Link>
                          <img
                            src="/images/Delete.svg "
                            className="img-fluid actionIcon"
                            onClick={() => setShowDelete(item?._id)}
                          />
                        </CheckPermission>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("user.notFound")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.users?.length}
        />
      </div>
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
      <FileModal
        show={show}
        handleClose={() => setShow(false)}
        handleAction={handleAction}
      />
    </div>
  );
};

export default Usermanagement;
