import React from "react";
import * as FaIcons from "react-icons/fa";
import {
  Customers,
  Dashboard,
  Data,
  Lable,
  Logout,
  Notifications,
  Orders,
  Payments,
  Printer,
  Products,
  Reports,
  Support,
  Temperature,
} from "./../../SvgIcons/allIcons";
export const SidebarData = [
  {
    icon: <Dashboard />,
    title: "sideBar.dashboard",
    path: "/dashboard",
  },
  {
    icon: <Customers />,
    title: "sideBar.userManagement",
    path: "/usermanagement",
    activePath: ["/usermanagement", "/addNewUser"],
  },
  {
    icon: <Payments />,
    title: "sideBar.paymentsBilling",
    path: "/paymentandbilling",
  },

  {
    icon: <Orders />,
    title: "sideBar.ordersTransactions",
    path: "/transactions",
  },
  {
    icon: <Products />,
    title: "sideBar.products",
    path: "/product",
  },

  {
    icon: <Reports />,
    title: "sideBar.reportAnalytics",
    path: "/reportsindex",
  },
  {
    icon: <Lable />,
    title: "sideBar.labelManagement",
    path: "/label",
  },
  {
    icon: <Data />,
    title: "sideBar.dataManagement",
    iconClosed: <FaIcons.FaAngleDown />,
    iconOpened: <FaIcons.FaAngleUp />,
    subNav: [
      {
        title: "sideBar.categories",
        path: "/categorylist",
        activePath: ["/categorylist", "/addcategory"],
      },
      {
        title: "sideBar.items",
        path: "/items",
        activePath: ["/items", "/add-item", "/item-details"],
      },
      {
        title: "sideBar.users",
        path: "/userlist",
        activePath: ["/userlist", "/addNewUser", "userdetail"],
      },
      {
        title: "sideBar.prepListBatches",
        path: "/prep-list",
        activePath: ["/prep-list", "/add-prep-list"],
      },
      {
        title: "sideBar.taskList",
        path: "/tasklist",
        activePath: ["/tasklist", "/addnewtask", "/task-details"],
      },
      {
        title: "sideBar.receivingList",
        path: "/receivinglist",
        activePath: ["/receivinglist", "/addreceivinglist"],
      },
    ],
  },

  {
    icon: <Printer />,
    title: "sideBar.printerManagement",
    path: "/printers",
  },
  // {
  //   icon: <Temperature />,
  //   title: "sideBar.temperatureMonitoring",
  //   path: "",
  // },
  {
    icon: <Notifications />,
    title: "sideBar.notificationNews",
    path: "/notifications",
  },
  // {
  //   icon: <Support />,
  //   title: "sideBar.supportTicket",
  //   path: "",
  // },
  {
    icon: <Logout />,
    title: "sideBar.logout",
    type: "logout",
  },
];
