import { useQueries } from "@tanstack/react-query";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import moment from "moment/moment";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";
import useDetails from "../../hooks/useDetails";
import useSlider from "../../hooks/useSlider";
import { getItemReports, getReports } from "../../services/services";
import { getMonthName } from "../../utils/common";
import { constant } from "../../utils/constants";
import Sidebar from "../sidebar/Sidebar";
import "./../../pages/Usermanagement/addNewUser.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: false,
    title: {
      display: true,
      text: "Total Dollars Spent",
    },
  },
};

const Reportsindex = () => {
  const details = useDetails();
  const isSlider = useSlider();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

 
  
    let params = {
      page: constant.PAGE_ONE,
      limit: constant.PER_PAGE_TEN,
    };
    const queryResults = useQueries({
      queries: [
        {
          queryKey: ["transactions", details?.companyUID],
          queryFn: async () => {
            const resp =
              
              (await getReports({
                ...params,
                groupBy: "month",
                companyId: details?.companyUID,
              }));
            return resp?.data?.data ?? [];
          },
        },
        {
          queryKey: ["item-report", details?.companyUID],
          queryFn: async () => {
            const resp =
            details?.companyUID && (await getItemReports(details?.companyUID, params));
            return resp?.data?.data ?? [];
          },
        },
      ],
    });
  
    const [reports, itemReport] = queryResults;
  
    const data = {  
      labels: reports?.data?.analytics?.map((i) => getMonthName(i?.month)),
      datasets: [
        {
          data: reports?.data?.analytics?.map((i) => i?.totalRevenue),
          backgroundColor: "#3a883a",
        },
      ],
    };
  
  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6} className="">
            <h2 className="mainhead font-24">Reports & Analytics</h2>
          </Col>
          {/* <Col xl={6}>
            <div className="d-flex align-items-center justify-content-end">
              <label className="labelText">Filter by:</label>
              <select
                className="form-select selectfeild"
                aria-label="Default select example"
              >
                <option selected>Weekly</option>
                <option value="1">Today</option>
                <option value="2">Yesterday</option>
                <option value="3">Weekly</option>
                <option value="3">Monthly</option>
                <option value="3">Every 3 Month</option>
                <option value="3">Every 6 Month</option>
                <option value="3">Every 9 month</option>
                <option value="3">Yearly</option>
              </select>
              <button className="userBtn btn-theme">Export Reports</button>
            </div>
          </Col> */}
        </Row>
        <Row>
          <Col className="tableOuter" xl={9}>
            <div className="d-flex align-items-baseline justify-content-between">
              <label className="font-18 colorDark pb-3">Transactions</label>
              <p className="mb-0 text-end ">
                <Link className="text-decoration-none linktext">View All</Link>
              </p>
            </div>
            <div className="table-responsive tableOut">
              <table className="table tableDashboard">
                <thead className="thead-dark">
                  <tr>
                    <th >Order ID</th>
                    <th >User Name</th>
                    <th >Amount</th>
                    <th >Date</th>
                    <th >Status</th>
                  </tr>
                </thead>
                <tbody>
                  {reports?.data?.transactions?.transactions &&
                  reports?.data?.transactions?.transactions?.length > 0 ? (
                    reports?.data?.transactions?.transactions?.map(
                      (item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item?.orderId}</td>
                            <td>{item?.userDetails?.userInfo?.name}</td>
                            <td>${item?.amount}</td>
                            <td>{moment(item?.createdAt).format("lll")}</td>
                            <td>
                              <p
                                className={
                                  item?.status ==
                                  constant?.TRANSACTION_STATUS?.SUCCESS
                                    ? "greenBox"
                                    : item?.status ==
                                      constant?.TRANSACTION_STATUS?.PENDING
                                    ? "greenBox yellowBox"
                                    : "greenBox redBox"
                                }
                              >
                                {item?.status ==
                                constant?.TRANSACTION_STATUS?.SUCCESS
                                  ? "Completed"
                                  : item?.status ==
                                    constant?.TRANSACTION_STATUS?.PENDING
                                  ? "Pending"
                                  : "Cancelled"}
                              </p>
                            </td>
                          </tr>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        No Transaction Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
          <Col xl={3}>
            {/* <div className="revenewImg mt-4"> */}
            <Bar options={options} data={data} />
              {/* <img src="./../images/revenew.png" className="img-fluid"></img> */}
            {/* </div> */}
          </Col>
        </Row>
        <Row>
          <Col className="tableOuter" xl={12}>
            <div className="d-flex align-items-baseline justify-content-between">
              <label className="font-18 colorDark pb-3">
                Product Waste Tracking
              </label>
              <p className="mb-0 text-end ">
                <Link className="text-decoration-none linktext">View All</Link>
              </p>
            </div>
            <div className="table-responsive tableOut">
              <table className="table tableDashboard">
                <thead className="thead-dark">
                  <tr>
                    <th >Item Name</th>
                    <th >Location</th>
                    <th >Prepped Quantity</th>
                    <th >Waste Quantity</th>
                    <th >Unit of Measure</th>
                    <th >Number of Label Printed</th>
                    <th >Net Weight</th>
                    <th >COGs Prepped</th>
                    <th >COGs Wasted</th>
                    <th >COGs Sold</th>
                  </tr>
                </thead>
                <tbody>
                  {itemReport?.data?.wasteTrackingList?.trackingList &&
                  itemReport?.data?.wasteTrackingList?.trackingList?.length >
                    0 ? (
                    itemReport?.data?.wasteTrackingList?.trackingList?.map(
                      (item, index) => {
                        return (
                          <tr key={index}>
                            <td>Blackened</td>
                            <td>sdfsd</td>
                            <td>100</td>
                            <td>100</td>
                            <td>100</td>
                            <td>100</td>
                            <td>10kg</td>
                            <td>50</td>
                            <td>50</td>
                            <td>50</td>
                          </tr>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        No Product Waste Tracking Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="tableOuter" xl={12}>
            <div className="d-flex align-items-baseline justify-content-between">
              <label className="font-18 colorDark pb-3">Item Prepared</label>
              <p className="mb-0 text-end ">
                <Link className="text-decoration-none linktext">View All</Link>
              </p>
            </div>
            <div className="table-responsive tableOut">
              <table className="table tableDashboard">
                <thead className="thead-dark">
                  <tr>
                    <th >Item Name</th>
                    <th >User or Employee</th>
                    <th >Location</th>
                    <th >Quantity of Labels Printed</th>
                    <th >Date & Time</th>
                  </tr>
                </thead>
                <tbody>
                  {itemReport?.data?.itemList?.items &&
                  itemReport?.data?.itemList?.items?.length < 0 ? (
                    itemReport?.data?.itemList?.items?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item?.en_name}</td>
                          <td>Wade Warren</td>
                          <td>13th Street 47 W 13th St, New York...</td>
                          <td>100</td>
                          <td>05/29/2024, 12:00 PM</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        No Item Prepared Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Reportsindex;
