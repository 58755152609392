import React from "react";
import { CrossIcon } from "../../../SvgIcons/allIcons";
import { nutritionalKeys } from "../../../utils/labelConstants";

const NutritionTable = ({
  element,
  handleRemoveObject,
  setSelectedElement,
  setItem,
  preview,
  subItem,
  setSubItem,
}) => {
  const handleDeleteSubItem = (id) => {
    setItem((prev) =>
      prev.map((el) =>
        el.id === element.id
          ? { ...el, subItem: el.subItem.filter((i) => i.id !== id) }
          : el
      )
    );
  };

  return (
    <div className="NutritionalTable h-100 d-flex flex-column position-relative">
      <div
        style={{
          height:
            element.styles.height + (element?.subItem?.length * 20 || 0) + "px",
          width: element.styles.width,
          padding: "10px",
          position: "relative",
        }}
      >
        <h5
          className="font20 text-center pb-1"
          {...element.input}
          onClick={() => {
            setSelectedElement(element);
            setSubItem("");
          }}
          role="button"
        >
          Nutrition Facts
        </h5>
        <div className="lowerTable mt-1 ">
          {element.subItem?.map((item, index) => {
            return (
              <div
                className="nutrition-parent position-relative w-100"
                key={index}
                onClick={() => {
                  setSubItem(item);
                  setSelectedElement(element);
                }}
                style={item?.input?.border}
              >
                {item?.key == nutritionalKeys.dv ? (
                  <p style={{ ...item.input.style }} className="w-100 text-end">
                    {item?.labelText}
                  </p>
                ) : item?.childKey == nutritionalKeys.calories ? (
                  <div className="calories">
                    <p style={{ ...item.input.style }}>Amount Per Serving</p>
                    <div className="calories-child">
                      <p
                        className="mb-0"
                        style={{ ...item.input.style }}
                      >
                        {item?.labelText}
                      </p>
                      <p
                        className="mb-0"
                        style={{
                          ...item.input.style,
                          fontSize:
                            parseInt(item.input.style.fontSize, 10) + 2 + "px",
                        }}
                      >
                        {item?.value}
                      </p>
                    </div>
                  </div>
                ) : item?.childKey == nutritionalKeys.serving_per_container ? (
                  <div className="serving">
                    <p style={{ ...item.input.style }}>
                      {`${item?.value} servings per container`}
                    </p>
                  </div>
                ) : item?.childKey == nutritionalKeys.servingSize ? (
                  <div className="nutritonal-facts">
                    <p
                      style={{ ...item.input.style }}
                    >{`${item?.labelText}`}</p>
                    <p style={{ ...item.input.style }}>{item?.value}</p>
                  </div>
                ) : (
                  <div className="nutritonal-facts">
                    <p
                      style={{ ...item.input.style }}
                    >{`${item?.labelText} ${item?.value}`}</p>
                    <p style={{ ...item.input.style }}>{item?.pValue}</p>
                  </div>
                )}

                {subItem?.id == item?.id && !preview && (
                  <span
                    className="cross-icon-label ms-1"
                    role="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteSubItem(item?.id);
                    }}
                  >
                    <CrossIcon size={7} />
                  </span>
                )}
              </div>
            );
          })}
        </div>
        <p
          style={{
            ...element.input.style,
            fontSize: "6px",
            position: "absolute",
            bottom: "3px",
            left: "3px",
            right: "3px",
            textAlign: "unset",
            lineHeight: "7px",
          }}
        >
          * The % Daily Value (DV) tells you how much a nutrient in a serving of
          food contributes to a daily diet. 2,000 calories a day is used for
          general nutrition advice.
        </p>
      </div>
      {!preview && (
        <span
          className="cross-icon-label ms-1"
          role="button"
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveObject(element.id);
            setSelectedElement("");
            setSubItem("");
          }}
        >
          <CrossIcon size={13} />
        </span>
      )}
    </div>
  );
};

export default NutritionTable;
